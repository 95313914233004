import React from 'react'
import Fade from 'react-reveal/Fade'
import Gallery from 'react-grid-gallery'

import lab from 'images/laboratorio_1.jpg'

import xray from 'images/raio_x.jpg'

import ultrassom from 'images/ultrassom.jpg'

import internament from 'images/internamento_1.jpg'

import cirurgia from 'images/cirurgia.jpg'
import cirurgia2 from 'images/cirurgia_2.jpg'
import cirurgia3 from 'images/cirurgia_3.jpg'

import Layout from 'layouts/index'
import TitleBox from 'components/TitleBox'

import cat from 'images/cat.jpg'

const photos = [
  {
    src: lab,
    thumbnail: lab,
    title: 'Lab',
    width: 1,
    height: 1,
  },
  {
    src: xray,
    thumbnail: xray,
    title: 'Raio X',
    width: 1,
    height: 1,
  },
  {
    src: ultrassom,
    thumbnail: ultrassom,
    title: 'Ultrassom',
    width: 1,
    height: 1,
  },
  {
    src: internament,
    thumbnail: internament,
    title: 'Internamento',
    width: 1,
    height: 1,
  },
  {
    src: cirurgia,
    thumbnail: cirurgia,
    title: 'Cirurgia',
    width: 1,
    height: 1,
  },
  {
    src: cirurgia2,
    thumbnail: cirurgia2,
    title: 'Cirurgia 2',
    width: 1,
    height: 1,
  },
  {
    src: cirurgia3,
    thumbnail: cirurgia,
    title: 'Cirurgia 3',
    width: 1,
    height: 1,
  },
]

const Galeria = () => {
  return (
    <Layout>
      <>
        <TitleBox title='Galeria de Fotos' bg={cat} />
        <Fade>
          <Gallery images={photos} enableImageSelection={false} />
        </Fade>
      </>
    </Layout>
  )
}

export default Galeria
